import { Mutex } from 'async-mutex';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { clearToken, setToken } from './appSlice';

// initialize an empty api service that we'll inject endpoints into later as needed
const mutex = new Mutex();
const baseQuery = fetchBaseQuery({
  baseUrl: '/api/',
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = getState().app.auth.tokenInfo;

    if (token && endpoint !== 'refreshUser') {
      headers.set('Authorization', `Bearer ${token.accessToken}`);
    }
    return headers;
  },
});

export const baseApi = createApi({
  keepUnusedDataFor: Infinity,
  baseQuery: async (args, api, extraOptions) => {
    const { expires, refreshToken, userInfo } = api.getState().app.auth;

    // check if we should get a new token
    if (expires && refreshToken && expires < Date.now()) {
      // checking whether the mutex is locked
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();

        // It seems that we have a refreshToken and an expire date. Let's see if
        // we have to refresh the credentials...
        const refreshResult = await baseQuery(
          {
            url: '/auth/user/refresh',
            body: {
              refreshToken,
              email: userInfo.email,
            },
            method: 'POST',
          },
          {
            ...api,
            endpoint: 'refreshUser',
          },
          extraOptions
        );

        if (refreshResult.data) {
          // store the new token
          api.dispatch(setToken(refreshResult.data));
        } else {
          api.dispatch(clearToken());
        }

        release();
      }
    }

    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    const result = await baseQuery(
      {
        responseHandler: 'content-type', // https://github.com/reduxjs/redux-toolkit/pull/2363
        ...args,
      },
      api,
      extraOptions
    );

    // The creators of RTK want to be able to use god-knows-what along with
    // HTTP and thus they want the code to be completely abstract and not care
    // about "headers". They also want the code to be SSR-able, which means that
    // no actual passing of any values that can't be serialized. All these
    // decisions lead to the fact that fulfillment callbacks don't have access
    // to the raw Response object (nor to the Headers nor pretty much anything).
    //
    // There is one workaround: hack the entire response payload and wrap it
    // inside an object. Of course, this would require that all fulfillment
    // handlers to be modified in order to "unwrap" the new data structure.
    //
    // Eg:
    //
    // Before this change:
    //
    // doFoo({ ... }).then(response => console.log(response.data));
    //
    // After this change:
    //
    // doFoo({ ... }).then(response => console.log(response.data.data));
    //
    // I'm not sure if we actually want to go down this rabbit hole, but since
    // I wasted an entire evening arguing with these stubborn people[0] until I
    // found how to actually do <this>, I thought I might as well leave this
    // comment here, just in case...
    //
    // [0] - https://github.com/reduxjs/redux-toolkit/issues/2723

    //if(("data" in resullt)) {
    //  return {
    //    data: {
    //      data: result.data,
    //      headers: Object.fromEntries(Array.from(result.meta.response.headers.entries())),
    //    },
    //    meta: result.meta,
    //  };
    //} else {
    //  // This means that we'll return an error obj such as { error, meta }
    //  return result;
    //}

    return result;
  },
  endpoints: () => ({}),
});
