import { baseApi as api } from './baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createAnalysis: build.mutation({
      query: (queryArg) => ({
        url: `/analysis`,
        method: 'POST',
        body: queryArg.body,
        headers: { forceCalculation: queryArg.forceCalculation },
      }),
    }),
    getAllAnalyses: build.query({
      query: (queryArg) => ({
        url: `/analysis`,
        headers: {
          tenantId: queryArg.tenantId,
          user: queryArg.user,
          channel: queryArg.channel,
          channelName: queryArg.channelName,
          channelUserId: queryArg.channelUserId,
          email: queryArg.email,
          validated: queryArg.validated,
          analysisId: queryArg.analysisId,
          status: queryArg.status,
          appId: queryArg.appId,
          dateStart: queryArg.dateStart,
          dateEnd: queryArg.dateEnd,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sort: queryArg.sort,
          filter: queryArg.filter,
          projection: queryArg.projection,
          raw: queryArg.raw,
        },
      }),
    }),
    updateAnalysisWithNewPicture: build.mutation({
      query: (queryArg) => ({
        url: `/analysis/${queryArg.analysisId}`,
        method: 'PUT',
        body: queryArg.body,
        headers: { user: queryArg.user, channel: queryArg.channel },
      }),
    }),
    getAnalysisByAnalysisId: build.query({
      query: (queryArg) => ({
        url: `/analysis/${queryArg.analysisId}`,
        headers: {
          user: queryArg.user,
          channel: queryArg.channel,
          tenantId: queryArg.tenantId,
          recalculate: queryArg.recalculate,
          raw: queryArg.raw,
        },
      }),
    }),
    updateAnalysisParameters: build.mutation({
      query: (queryArg) => ({
        url: `/analysis/${queryArg.analysisId}/params`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          user: queryArg.user,
          channel: queryArg.channel,
          forceCalculation: queryArg.forceCalculation,
          raw: queryArg.raw,
        },
      }),
    }),
    getAnalysisPicture: build.query({
      query: (queryArg) => ({
        url: `/analysis/${queryArg.analysisId}/images/${queryArg.imageType}`,
        headers: {
          tenantid: queryArg.tenantid,
          user: queryArg.user,
          channel: queryArg.channel,
          transparent: queryArg.transparent,
        },
      }),
    }),
    addOtherImageToanalysis: build.mutation({
      query: (queryArg) => ({
        url: `/analysis/${queryArg.analysisId}/images/${queryArg.imageType}`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          tenantid: queryArg.tenantid,
          user: queryArg.user,
          channel: queryArg.channel,
        },
      }),
    }),
    getAnalysisRecommendation: build.query({
      query: (queryArg) => ({
        url: `/analysis/${queryArg.analysisId}/recommendation`,
        headers: { user: queryArg.user, channel: queryArg.channel },
      }),
    }),
    sendAnalysisQuizzAnswer: build.mutation({
      query: (queryArg) => ({
        url: `/analysis/${queryArg.analysisId}/quizz`,
        method: 'POST',
        body: queryArg.quizzAnswer,
        headers: { user: queryArg.user, channel: queryArg.channel },
      }),
    }),
    getAnalysisFacemesh: build.query({
      query: (queryArg) => ({
        url: `/analysis/${queryArg.analysisId}/facemesh`,
        headers: {
          user: queryArg.user,
          channel: queryArg.channel,
          tenantid: queryArg.tenantid,
        },
      }),
    }),
    getAnalysisDifferences: build.query({
      query: (queryArg) => ({
        url: `/analysis/${queryArg.analysisId}/differences`,
        headers: {
          user: queryArg.user,
          channel: queryArg.channel,
          tenantid: queryArg.tenantid,
        },
      }),
    }),
    getAnalysisMasks: build.query({
      query: (queryArg) => ({
        url: `/analysis/${queryArg.analysisId}/masks`,
        headers: {
          user: queryArg.user,
          channel: queryArg.channel,
          tenantid: queryArg.tenantid,
        },
      }),
    }),
    getAnalysisMaskPoints: build.query({
      query: (queryArg) => ({
        url: `/analysis/${queryArg.analysisId}/masks/${queryArg.maskName}/points`,
        headers: {
          user: queryArg.user,
          channel: queryArg.channel,
          tenantid: queryArg.tenantid,
        },
      }),
    }),
    getAnalysisMaskImage: build.query({
      query: (queryArg) => ({
        url: `/analysis/${queryArg.analysisId}/masks/${queryArg.maskName}/image`,
        headers: {
          user: queryArg.user,
          channel: queryArg.channel,
          tenantid: queryArg.tenantid,
        },
      }),
    }),
    transformAnalysisWithTransformerFromTenant: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/analysis/${queryArg.analysisId}/validateTransformationInput`,
        method: 'POST',
        body: queryArg.recommenderTransformation,
      }),
    }),
    sendEmailRecommendation: build.mutation({
      query: (queryArg) => ({
        url: `/analysis/${queryArg.analysisId}/sendrecommendation`,
        method: 'POST',
        headers: { user: queryArg.user, channel: queryArg.channel },
      }),
    }),
    createExportAnalysesTask: build.mutation({
      query: (queryArg) => ({
        url: `/analysis/export`,
        method: 'POST',
        headers: {
          tenantId: queryArg.tenantId,
          user: queryArg.user,
          channel: queryArg.channel,
          channelName: queryArg.channelName,
          channelUserId: queryArg.channelUserId,
          email: queryArg.email,
          validated: queryArg.validated,
          analysisId: queryArg.analysisId,
          status: queryArg.status,
          appId: queryArg.appId,
          dateStart: queryArg.dateStart,
          dateEnd: queryArg.dateEnd,
          sort: queryArg.sort,
          filter: queryArg.filter,
          projection: queryArg.projection,
          raw: queryArg.raw,
        },
        params: { format: queryArg.format },
      }),
    }),
    getAnalysisExportFile: build.query({
      query: (queryArg) => ({
        url: `/analysis/export/file/${queryArg.fileName}`,
        headers: { tenantId: queryArg.tenantId },
      }),
    }),
    createAnalysisTemporalLink: build.mutation({
      query: (queryArg) => ({
        url: `/analysis/link/${queryArg.analysisId}`,
        method: 'POST',
        headers: { user: queryArg.user, channel: queryArg.channel },
      }),
    }),
    getAnalysisFromTemporalCode: build.query({
      query: (queryArg) => ({ url: `/analysis/link/${queryArg.code}` }),
    }),
    startAnalysisOtpProcess: build.mutation({
      query: (queryArg) => ({
        url: `/analysis/${queryArg.analysisId}/setmail`,
        method: 'POST',
        body: queryArg.analysisEmail,
        headers: { channel: queryArg.channel, user: queryArg.user },
      }),
    }),
    validateAnalysisOtp: build.query({
      query: (queryArg) => ({
        url: `/analysis/${queryArg.uniqueId}/validateotp`,
      }),
    }),
    linkAnalysisWithUser: build.mutation({
      query: (queryArg) => ({
        url: `/analysis/${queryArg.analysisId}/linkuser/${queryArg.email}`,
        method: 'POST',
        body: queryArg.body,
        headers: { channel: queryArg.channel, user: queryArg.user },
      }),
    }),
    patchAnalysisChannelUser: build.mutation({
      query: (queryArg) => ({
        url: `/analysis/${queryArg.analysisId}/channelUser`,
        method: 'PATCH',
        body: queryArg.body,
        headers: { user: queryArg.user, channel: queryArg.channel },
      }),
    }),
    getAnalysisMesh: build.query({
      query: (queryArg) => ({
        url: `/analysis/${queryArg.analysisId}/mesh`,
        headers: {
          user: queryArg.user,
          channel: queryArg.channel,
          tenantid: queryArg.tenantid,
        },
      }),
    }),
    getAllApps: build.query({
      query: () => ({ url: `/apps` }),
    }),
    insertApp: build.mutation({
      query: (queryArg) => ({
        url: `/apps`,
        method: 'POST',
        body: queryArg.appData,
      }),
    }),
    getAppByAppId: build.query({
      query: (queryArg) => ({ url: `/apps/${queryArg.appId}` }),
    }),
    patchApp: build.mutation({
      query: (queryArg) => ({
        url: `/apps/${queryArg.appId}`,
        method: 'PATCH',
        body: queryArg.appData,
      }),
    }),
    deleteApp: build.mutation({
      query: (queryArg) => ({
        url: `/apps/${queryArg.appId}`,
        method: 'DELETE',
      }),
    }),
    getGenevaPalette: build.mutation({
      query: (queryArg) => ({
        url: `/geneva/palette`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getGenevaSegmentation: build.mutation({
      query: (queryArg) => ({
        url: `/geneva/segment`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getGenevaEnhance: build.mutation({
      query: (queryArg) => ({
        url: `/geneva/enhance`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getGenevaMakeup: build.mutation({
      query: (queryArg) => ({
        url: `/geneva/makeup`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getAllGenevaMakeupModels: build.query({
      query: () => ({ url: `/geneva/makeupmodels` }),
    }),
    createGenevaMakeupModel: build.mutation({
      query: (queryArg) => ({
        url: `/geneva/makeupmodels`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getOneGenevaMakeupModel: build.query({
      query: (queryArg) => ({
        url: `/geneva/makeupmodels/${queryArg.modelId}`,
      }),
    }),
    updateGenevaMakeupModel: build.mutation({
      query: (queryArg) => ({
        url: `/geneva/makeupmodels/${queryArg.modelId}`,
        method: 'PATCH',
        body: queryArg.body,
      }),
    }),
    removeGenevaMakeupModel: build.mutation({
      query: (queryArg) => ({
        url: `/geneva/makeupmodels/${queryArg.modelId}`,
        method: 'DELETE',
      }),
    }),
    loginUser: build.mutation({
      query: (queryArg) => ({
        url: `/auth/user/login`,
        method: 'POST',
        body: queryArg.user,
      }),
    }),
    loginApp: build.mutation({
      query: (queryArg) => ({
        url: `/auth/app/login`,
        method: 'POST',
        body: queryArg.app,
      }),
    }),
    refreshUser: build.mutation({
      query: (queryArg) => ({
        url: `/auth/user/refresh`,
        method: 'POST',
        body: queryArg.authRefreshUser,
      }),
    }),
    logoutUser: build.mutation({
      query: (queryArg) => ({
        url: `/auth/user/logout`,
        method: 'POST',
        body: queryArg.authRefreshUser,
      }),
    }),
    refreshApp: build.mutation({
      query: (queryArg) => ({
        url: `/auth/app/refresh`,
        method: 'POST',
        body: queryArg.authRefreshApp,
      }),
    }),
    getAuthUserWhoami: build.query({
      query: () => ({ url: `/auth/user/whoami` }),
    }),
    getAuthAppWhoami: build.query({
      query: () => ({ url: `/auth/app/whoami` }),
    }),
    getAllChannelUsers: build.query({
      query: (queryArg) => ({
        url: `/channelUsers`,
        params: { full: queryArg.full },
      }),
    }),
    createChannelUser: build.mutation({
      query: (queryArg) => ({
        url: `/channelUsers`,
        method: 'POST',
        body: queryArg.channelUserData,
      }),
    }),
    getChannelUser: build.query({
      query: (queryArg) => ({
        url: `/channelUsers/${queryArg.id}`,
        params: { full: queryArg.full },
      }),
    }),
    updateChannelUser: build.mutation({
      query: (queryArg) => ({
        url: `/channelUsers/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.channelUserData,
      }),
    }),
    deleteChannelUser: build.mutation({
      query: (queryArg) => ({
        url: `/channelUsers/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    deleteChannelUserAndAllData: build.mutation({
      query: (queryArg) => ({
        url: `/channelUsers/${queryArg.id}/all`,
        method: 'DELETE',
      }),
    }),
    getAllChannelUsersByChannel: build.query({
      query: (queryArg) => ({
        url: `/channelUsers/byChannel/${queryArg.channel}`,
        params: { full: queryArg.full },
      }),
    }),
    getChannelUserByChannel: build.query({
      query: (queryArg) => ({
        url: `/channelUsers/byChannel/${queryArg.channel}/${queryArg.channelUserId}`,
        params: { full: queryArg.full },
      }),
    }),
    getChannelUserContext: build.query({
      query: (queryArg) => ({ url: `/channelUsers/${queryArg.id}/context` }),
    }),
    updateChannelUserContext: build.mutation({
      query: (queryArg) => ({
        url: `/channelUsers/${queryArg.id}/context`,
        method: 'PUT',
        body: queryArg.channelUserObj,
      }),
    }),
    patchChannelUserContext: build.mutation({
      query: (queryArg) => ({
        url: `/channelUsers/${queryArg.id}/context`,
        method: 'PATCH',
        body: queryArg.channelUserObj,
      }),
    }),
    deleteChannelUserContext: build.mutation({
      query: (queryArg) => ({
        url: `/channelUsers/${queryArg.id}/context`,
        method: 'DELETE',
      }),
    }),
    getChannelUserContexts: build.query({
      query: (queryArg) => ({
        url: `/channelUsers/${queryArg.id}/context/all`,
      }),
    }),
    getChannelUserConsolidatedContext: build.query({
      query: (queryArg) => ({
        url: `/channelUsers/${queryArg.id}/context/consolidated`,
        params: { full: queryArg.full },
      }),
    }),
    linkChannelUsers: build.mutation({
      query: (queryArg) => ({
        url: `/channelUsers/link`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    unlinkChannelUser: build.mutation({
      query: (queryArg) => ({
        url: `/channelUsers/unlink`,
        method: 'POST',
        body: queryArg.userIdentification,
      }),
    }),
    createChannelUserConsents: build.mutation({
      query: (queryArg) => ({
        url: `/channelUsers/${queryArg.id}/consent`,
        method: 'POST',
        body: queryArg.channelUserConsents,
      }),
    }),
    getAllUsers: build.query({
      query: () => ({ url: `/users` }),
    }),
    createUser: build.mutation({
      query: (queryArg) => ({
        url: `/users`,
        method: 'POST',
        body: queryArg.userData,
      }),
    }),
    getUser: build.query({
      query: (queryArg) => ({ url: `/users/${queryArg.userId}` }),
    }),
    patchUser: build.mutation({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}`,
        method: 'PATCH',
        body: queryArg.userData,
      }),
    }),
    deleteUser: build.mutation({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}`,
        method: 'DELETE',
      }),
    }),
    modifyEmailByUserId: build.mutation({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/modifyemail`,
        method: 'PATCH',
        body: queryArg.userEmail,
      }),
    }),
    modifyEmail: build.mutation({
      query: (queryArg) => ({
        url: `/users/modifyemail`,
        method: 'PATCH',
        body: queryArg.userEmailPair,
      }),
    }),
    deleteUserAndAllData: build.mutation({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/all`,
        method: 'DELETE',
      }),
    }),
    ensureUserLinkedWithChannelUser: build.mutation({
      query: (queryArg) => ({
        url: `/users/link`,
        method: 'POST',
        body: queryArg.userLink,
      }),
    }),
    getUserTotp: build.query({
      query: (queryArg) => ({ url: `/users/generatetotp/${queryArg.email}` }),
    }),
    storeUserTotp: build.mutation({
      query: (queryArg) => ({
        url: `/users/storetotp/${queryArg.email}`,
        method: 'POST',
        body: queryArg.totpSetupData,
      }),
    }),
    rotateUserTotp: build.mutation({
      query: (queryArg) => ({
        url: `/users/rotatetotp/${queryArg.email}`,
        method: 'PUT',
      }),
    }),
    initRecoverPassword: build.mutation({
      query: (queryArg) => ({
        url: `/users/recoverPassword/${queryArg.email}`,
        method: 'POST',
      }),
    }),
    validateRecoverPassword: build.mutation({
      query: (queryArg) => ({
        url: `/users/recoverPassword/${queryArg.email}/token/${queryArg.token}`,
        method: 'POST',
        body: queryArg.passwordObject,
      }),
    }),
    startPlanner: build.mutation({
      query: () => ({ url: `/planner/start`, method: 'PUT' }),
    }),
    stopPlanner: build.mutation({
      query: () => ({ url: `/planner/stop`, method: 'PUT' }),
    }),
    reloadPlanner: build.mutation({
      query: () => ({ url: `/planner/reload`, method: 'PUT' }),
    }),
    refreshPlanner: build.mutation({
      query: () => ({ url: `/planner/refresh`, method: 'PUT' }),
    }),
    abortAllPlanner: build.mutation({
      query: () => ({ url: `/planner/abortall`, method: 'PUT' }),
    }),
    deferAllPlanner: build.mutation({
      query: () => ({ url: `/planner/deferall`, method: 'PUT' }),
    }),
    getExecutorsPlanner: build.query({
      query: () => ({ url: `/planner/executors` }),
    }),
    getPlannerStatus: build.query({
      query: () => ({ url: `/planner/status` }),
    }),
    getPlannerByTenantIdStats: build.query({
      query: (queryArg) => ({
        url: `/planner/${queryArg.tenantId}/stats`,
        params: { fromDate: queryArg.fromDate, toDate: queryArg.toDate },
      }),
    }),
    getAllTaskPlanners: build.query({
      query: (queryArg) => ({
        url: `/planner/${queryArg.tenantId}/taskplanners`,
      }),
    }),
    createTaskPlanner: build.mutation({
      query: (queryArg) => ({
        url: `/planner/${queryArg.tenantId}/taskplanners`,
        method: 'POST',
        body: queryArg.taskPlannerData,
        headers: { encryptsettings: queryArg.encryptsettings },
      }),
    }),
    getOneTaskPlanner: build.query({
      query: (queryArg) => ({
        url: `/planner/${queryArg.tenantId}/taskplanners/${queryArg.taskPlannerId}`,
      }),
    }),
    updateTaskPlanner: build.mutation({
      query: (queryArg) => ({
        url: `/planner/${queryArg.tenantId}/taskplanners/${queryArg.taskPlannerId}`,
        method: 'PUT',
        body: queryArg.taskPlannerData,
        headers: { encryptsettings: queryArg.encryptsettings },
      }),
    }),
    deleteTaskPlanner: build.mutation({
      query: (queryArg) => ({
        url: `/planner/${queryArg.tenantId}/taskplanners/${queryArg.taskPlannerId}`,
        method: 'DELETE',
      }),
    }),
    startTaskPlanner: build.mutation({
      query: (queryArg) => ({
        url: `/planner/${queryArg.tenantId}/taskplanners/${queryArg.taskPlannerId}/start`,
        method: 'PUT',
      }),
    }),
    stopTaskPlanner: build.mutation({
      query: (queryArg) => ({
        url: `/planner/${queryArg.tenantId}/taskplanners/${queryArg.taskPlannerId}/stop`,
        method: 'PUT',
      }),
    }),
    refreshTaskPlanner: build.mutation({
      query: (queryArg) => ({
        url: `/planner/${queryArg.tenantId}/taskplanners/${queryArg.taskPlannerId}/refresh`,
        method: 'PUT',
      }),
    }),
    deferAllTaskPlanner: build.mutation({
      query: (queryArg) => ({
        url: `/planner/${queryArg.tenantId}/taskplanners/${queryArg.taskPlannerId}/deferall`,
        method: 'PUT',
      }),
    }),
    abortAllTaskPlanner: build.mutation({
      query: (queryArg) => ({
        url: `/planner/${queryArg.tenantId}/taskplanners/${queryArg.taskPlannerId}/abortall`,
        method: 'PUT',
      }),
    }),
    getTenantPlannerStatus: build.query({
      query: (queryArg) => ({ url: `/planner/${queryArg.tenantId}/status` }),
    }),
    getTaskPlannerStatus: build.query({
      query: (queryArg) => ({
        url: `/planner/${queryArg.tenantId}/taskplanners/${queryArg.taskPlannerId}/status`,
      }),
    }),
    getAllTasks: build.query({
      query: (queryArg) => ({
        url: `/planner/${queryArg.tenantId}/tasks`,
        headers: {
          planner: queryArg.planner,
          state: queryArg.state,
          dateStart: queryArg.dateStart,
          dateEnd: queryArg.dateEnd,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sort: queryArg.sort,
          filter: queryArg.filter,
          projection: queryArg.projection,
        },
      }),
    }),
    createTask: build.mutation({
      query: (queryArg) => ({
        url: `/planner/${queryArg.tenantId}/tasks`,
        method: 'POST',
        body: queryArg.taskData,
      }),
    }),
    getOneTask: build.query({
      query: (queryArg) => ({
        url: `/planner/${queryArg.tenantId}/tasks/${queryArg.taskId}`,
      }),
    }),
    updateTask: build.mutation({
      query: (queryArg) => ({
        url: `/planner/${queryArg.tenantId}/tasks/${queryArg.taskId}`,
        method: 'PUT',
        body: queryArg.taskData,
      }),
    }),
    deleteTask: build.mutation({
      query: (queryArg) => ({
        url: `/planner/${queryArg.tenantId}/tasks/${queryArg.taskId}`,
        method: 'DELETE',
      }),
    }),
    getDatabases: build.query({
      query: (queryArg) => ({
        url: `/databases`,
        params: { unused: queryArg.unused, tenantId: queryArg.tenantId },
      }),
    }),
    postDatabases: build.mutation({
      query: (queryArg) => ({
        url: `/databases`,
        method: 'POST',
        body: queryArg.databaseConnectionData,
      }),
    }),
    getDatabasesByDatabaseId: build.query({
      query: (queryArg) => ({ url: `/databases/${queryArg.databaseId}` }),
    }),
    putDatabasesByDatabaseId: build.mutation({
      query: (queryArg) => ({
        url: `/databases/${queryArg.databaseId}`,
        method: 'PUT',
        body: queryArg.databaseConnectionData,
      }),
    }),
    patchDatabasesByDatabaseId: build.mutation({
      query: (queryArg) => ({
        url: `/databases/${queryArg.databaseId}`,
        method: 'PATCH',
        body: queryArg.databaseConnectionData,
      }),
    }),
    deleteDatabasesByDatabaseId: build.mutation({
      query: (queryArg) => ({
        url: `/databases/${queryArg.databaseId}`,
        method: 'DELETE',
      }),
    }),
    getStorages: build.query({
      query: (queryArg) => ({
        url: `/storages`,
        params: { unused: queryArg.unused, tenantId: queryArg.tenantId },
      }),
    }),
    postStorages: build.mutation({
      query: (queryArg) => ({
        url: `/storages`,
        method: 'POST',
        body: queryArg.storageConnectionData,
      }),
    }),
    getStoragesByStorageId: build.query({
      query: (queryArg) => ({ url: `/storages/${queryArg.storageId}` }),
    }),
    putStoragesByStorageId: build.mutation({
      query: (queryArg) => ({
        url: `/storages/${queryArg.storageId}`,
        method: 'PUT',
        body: queryArg.storageConnectionData,
      }),
    }),
    patchStoragesByStorageId: build.mutation({
      query: (queryArg) => ({
        url: `/storages/${queryArg.storageId}`,
        method: 'PATCH',
        body: queryArg.storageConnectionData,
      }),
    }),
    deleteStoragesByStorageId: build.mutation({
      query: (queryArg) => ({
        url: `/storages/${queryArg.storageId}`,
        method: 'DELETE',
      }),
    }),
    getDatabaseByCollectionName: build.query({
      query: (queryArg) => ({
        url: `/database/${queryArg.collectionName}`,
        headers: { filter: queryArg.filter },
      }),
    }),
    postDatabaseByCollectionName: build.mutation({
      query: (queryArg) => ({
        url: `/database/${queryArg.collectionName}`,
        method: 'POST',
        body: queryArg.data,
      }),
    }),
    getDatabaseByCollectionNameFindOne: build.query({
      query: (queryArg) => ({
        url: `/database/${queryArg.collectionName}/findOne`,
        headers: { filter: queryArg.filter },
      }),
    }),
    getDatabaseByCollectionNameAndItemId: build.query({
      query: (queryArg) => ({
        url: `/database/${queryArg.collectionName}/${queryArg.itemId}`,
      }),
    }),
    putDatabaseByCollectionNameAndItemId: build.mutation({
      query: (queryArg) => ({
        url: `/database/${queryArg.collectionName}/${queryArg.itemId}`,
        method: 'PUT',
        body: queryArg.data,
      }),
    }),
    patchDatabaseByCollectionNameAndItemId: build.mutation({
      query: (queryArg) => ({
        url: `/database/${queryArg.collectionName}/${queryArg.itemId}`,
        method: 'PATCH',
        body: queryArg.data,
      }),
    }),
    deleteDatabaseByCollectionNameAndItemId: build.mutation({
      query: (queryArg) => ({
        url: `/database/${queryArg.collectionName}/${queryArg.itemId}`,
        method: 'DELETE',
      }),
    }),
    getStorageByContainerNameAndFileId: build.query({
      query: (queryArg) => ({
        url: `/storage/${queryArg.containerName}/${queryArg.fileId}`,
      }),
    }),
    deleteStorageByContainerNameAndFileId: build.mutation({
      query: (queryArg) => ({
        url: `/storage/${queryArg.containerName}/${queryArg.fileId}`,
        method: 'DELETE',
      }),
    }),
    getStorageImageByContainerNameAndFileId: build.query({
      query: (queryArg) => ({
        url: `/storage/image/${queryArg.containerName}/${queryArg.fileId}`,
      }),
    }),
    postStorageByContainerName: build.mutation({
      query: (queryArg) => ({
        url: `/storage/${queryArg.containerName}`,
        method: 'POST',
        body: queryArg.storage,
      }),
    }),
    postStorageImageByContainerName: build.mutation({
      query: (queryArg) => ({
        url: `/storage/image/${queryArg.containerName}`,
        method: 'POST',
        body: queryArg.storage,
      }),
    }),
    insertFileInVolume: build.mutation({
      query: (queryArg) => ({
        url: `/storage/volume/${queryArg.folderName}`,
        method: 'POST',
        body: queryArg.storage,
        headers: { tenantId: queryArg.tenantId },
      }),
    }),
    deleteFileFromVolume: build.mutation({
      query: (queryArg) => ({
        url: `/storage/volume/${queryArg.folderName}/${queryArg.fileId}`,
        method: 'DELETE',
        headers: { tenantId: queryArg.tenantId },
      }),
    }),
    getTenantGroups: build.query({
      query: () => ({ url: `/tenantgroups` }),
    }),
    addTenantGroup: build.mutation({
      query: (queryArg) => ({
        url: `/tenantgroups`,
        method: 'POST',
        body: queryArg.tenantGroupData,
      }),
    }),
    getTenantGroup: build.query({
      query: (queryArg) => ({ url: `/tenantgroups/${queryArg.tenantGroupId}` }),
    }),
    updateTenantGroup: build.mutation({
      query: (queryArg) => ({
        url: `/tenantgroups/${queryArg.tenantGroupId}`,
        method: 'PUT',
        body: queryArg.tenantGroupData,
      }),
    }),
    deleteTenantGroup: build.mutation({
      query: (queryArg) => ({
        url: `/tenantgroups/${queryArg.tenantGroupId}`,
        method: 'DELETE',
      }),
    }),
    syncTenantsWithTenant: build.mutation({
      query: (queryArg) => ({
        url: `/tenantgroups/${queryArg.tenantGroupId}/sync/${queryArg.tenantId}`,
        method: 'PUT',
        headers: { collections: queryArg.collections, rules: queryArg.rules },
      }),
    }),
    setFormulaForTenantGroup: build.mutation({
      query: (queryArg) => ({
        url: `/tenantgroups/${queryArg.tenantGroupId}/formulas/${queryArg.formulaId}`,
        method: 'PUT',
        body: queryArg.formula,
      }),
    }),
    removeFormulaFromTenantGroup: build.mutation({
      query: (queryArg) => ({
        url: `/tenantgroups/${queryArg.tenantGroupId}/formulas/${queryArg.formulaId}`,
        method: 'DELETE',
      }),
    }),
    setFormulaInfoForTenantGroup: build.mutation({
      query: (queryArg) => ({
        url: `/tenantgroups/${queryArg.tenantGroupId}/formulasinfo/${queryArg.formulaId}`,
        method: 'PUT',
        body: queryArg.formulaInfo,
      }),
    }),
    removeFormulaInfoFromTenantGroup: build.mutation({
      query: (queryArg) => ({
        url: `/tenantgroups/${queryArg.tenantGroupId}/formulasinfo/${queryArg.formulaId}`,
        method: 'DELETE',
      }),
    }),
    setFormulaInfoLocaleForTenantGroup: build.mutation({
      query: (queryArg) => ({
        url: `/tenantgroups/${queryArg.tenantGroupId}/formulasinfo/${queryArg.formulaId}/locale/${queryArg.locale}`,
        method: 'PUT',
        body: queryArg.formulaInfoLocaleData,
      }),
    }),
    removeFormulaInfoLocaleFromTenantGroup: build.mutation({
      query: (queryArg) => ({
        url: `/tenantgroups/${queryArg.tenantGroupId}/formulasinfo/${queryArg.formulaId}/locales/${queryArg.locale}`,
        method: 'DELETE',
      }),
    }),
    uploadRulesExcelToTenantGroup: build.mutation({
      query: (queryArg) => ({
        url: `/tenantgroups/${queryArg.tenantGroupId}/rules`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    deleteRuleFromTenantGroup: build.mutation({
      query: (queryArg) => ({
        url: `/tenantgroups/${queryArg.tenantGroupId}/rules/${queryArg.name}`,
        method: 'DELETE',
      }),
    }),
    addTransformerToTenantGroup: build.mutation({
      query: (queryArg) => ({
        url: `/tenantgroups/${queryArg.tenantGroupId}/transformers`,
        method: 'POST',
        body: queryArg.transformerData,
      }),
    }),
    updateTransformerOfTenantGroup: build.mutation({
      query: (queryArg) => ({
        url: `/tenantgroups/${queryArg.tenantGroupId}/transformers/${queryArg.transformerId}`,
        method: 'PATCH',
        body: queryArg.transformerData,
      }),
    }),
    deleteTransformerFromTenantGroup: build.mutation({
      query: (queryArg) => ({
        url: `/tenantgroups/${queryArg.tenantGroupId}/transformers/${queryArg.transformerId}`,
        method: 'DELETE',
      }),
    }),
    getAllStatsFromTenantGroup: build.query({
      query: (queryArg) => ({
        url: `/tenantgroups/${queryArg.tenantGroupId}/stats`,
        params: { fromDate: queryArg.fromDate, toDate: queryArg.toDate },
      }),
    }),
    getAllChatbots: build.query({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/chatbots` }),
    }),
    insertChatbot: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/chatbots`,
        method: 'POST',
        body: queryArg.chatbotData,
      }),
    }),
    getOneChatbot: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/chatbots/${queryArg.chatbotId}`,
      }),
    }),
    updateChatbot: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/chatbots/${queryArg.chatbotId}`,
        method: 'PATCH',
        body: queryArg.chatbotData,
      }),
    }),
    deleteChatbot: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/chatbots/${queryArg.chatbotId}`,
        method: 'DELETE',
      }),
    }),
    askChatbot: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/chatbots/${queryArg.chatbotId}/ask`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getAllEvents: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/events`,
        headers: {
          dateStart: queryArg.dateStart,
          dateEnd: queryArg.dateEnd,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sort: queryArg.sort,
          filter: queryArg.filter,
        },
      }),
    }),
    getOneEvent: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/events/${queryArg.eventId}`,
      }),
    }),
    getFormulas: build.query({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/formulas` }),
    }),
    getFormula: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/formulas/${queryArg.formulaId}`,
      }),
    }),
    updateFormula: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/formulas/${queryArg.formulaId}`,
        method: 'PUT',
        body: queryArg.formula,
      }),
    }),
    deleteFormula: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/formulas/${queryArg.formulaId}`,
        method: 'DELETE',
      }),
    }),
    addFormulasTests: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/formulas/tests`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getFormulasTests: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/formulas/tests`,
      }),
    }),
    getFormulasInfo: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/formulasinfo`,
      }),
    }),
    getFormulaInfo: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/formulasinfo/${queryArg.formulaId}`,
      }),
    }),
    updateFormulaInfo: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/formulasinfo/${queryArg.formulaId}`,
        method: 'PUT',
        body: queryArg.formulaInfo,
      }),
    }),
    deleteFormulaInfo: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/formulasinfo/${queryArg.formulaId}`,
        method: 'DELETE',
      }),
    }),
    getFormulasInfoLocales: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/formulasinfo/locale/${queryArg.locale}`,
      }),
    }),
    getFormulaInfoLocale: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/formulasinfo/${queryArg.formulaId}/locale/${queryArg.locale}`,
      }),
    }),
    updateFormulaInfoLocale: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/formulasinfo/${queryArg.formulaId}/locale/${queryArg.locale}`,
        method: 'PUT',
        body: queryArg.formulaInfoLocaleData,
      }),
    }),
    deleteFormulaInfoLocale: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/formulasinfo/${queryArg.formulaId}/locales/${queryArg.locale}`,
        method: 'DELETE',
      }),
    }),
    getAllProducts: build.query({
      query: (queryArg) => ({
        url: `/products`,
        headers: {
          tenantId: queryArg.tenantId,
          listids: queryArg.listids,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sort: queryArg.sort,
          filter: queryArg.filter,
          projection: queryArg.projection,
          isOutOfStock: queryArg.isOutOfStock,
          substring: queryArg.substring,
        },
      }),
    }),
    createProduct: build.mutation({
      query: (queryArg) => ({
        url: `/products`,
        method: 'POST',
        body: queryArg.productData,
        headers: {
          tenantId: queryArg.tenantId,
          applyTransform: queryArg.applyTransform,
        },
      }),
    }),
    getAllProductsByLocale: build.query({
      query: (queryArg) => ({
        url: `/products/locale/${queryArg.locale}`,
        headers: {
          tenantId: queryArg.tenantId,
          listids: queryArg.listids,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sort: queryArg.sort,
          filter: queryArg.filter,
          projection: queryArg.projection,
        },
      }),
    }),
    getProduct: build.query({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}`,
        headers: { tenantId: queryArg.tenantId },
      }),
    }),
    patchProduct: build.mutation({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}`,
        method: 'PATCH',
        body: queryArg.productData,
        headers: { tenantId: queryArg.tenantId },
      }),
    }),
    deleteProduct: build.mutation({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}`,
        method: 'DELETE',
        headers: { tenantId: queryArg.tenantId },
      }),
    }),
    getProductByLocale: build.query({
      query: (queryArg) => ({
        url: `/products/${queryArg.productId}/locale/${queryArg.locale}`,
        headers: { tenantId: queryArg.tenantId },
      }),
    }),
    getAllProductsFromTenant: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/products`,
        headers: { isOutOfStock: queryArg.isOutOfStock },
      }),
    }),
    createProductInTenant: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/products`,
        method: 'POST',
        body: queryArg.productData,
        headers: { applyTransform: queryArg.applyTransform },
      }),
    }),
    getAllProductsByLocaleFromTenant: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/products/locale/${queryArg.locale}`,
      }),
    }),
    getProductFromTenant: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/products/${queryArg.productId}`,
      }),
    }),
    patchProductFromTenant: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/products/${queryArg.productId}`,
        method: 'PATCH',
        body: queryArg.productData,
      }),
    }),
    deleteProductFromTenant: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/products/${queryArg.productId}`,
        method: 'DELETE',
      }),
    }),
    getProductByLocaleFromTenant: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/products/${queryArg.productId}/locale/${queryArg.locale}`,
      }),
    }),
    productsBatchEnd: build.mutation({
      query: (queryArg) => ({
        url: `/products/batch/end`,
        method: 'POST',
        headers: { tenantId: queryArg.tenantId },
      }),
    }),
    createProductsBatch: build.mutation({
      query: (queryArg) => ({
        url: `/products/batch`,
        method: 'POST',
        body: queryArg.body,
        headers: { tenantId: queryArg.tenantId },
      }),
    }),
    insertProductsBatchExcel: build.mutation({
      query: (queryArg) => ({
        url: `/products/batch/excel`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          tenantId: queryArg.tenantId,
          deleteOldProducts: queryArg.deleteOldProducts,
        },
      }),
    }),
    exportProductsExcel: build.query({
      query: (queryArg) => ({
        url: `/products/export/excel`,
        headers: { tenantId: queryArg.tenantId },
      }),
    }),
    getAllQuestions: build.query({
      query: () => ({ url: `/questions` }),
    }),
    createQuestion: build.mutation({
      query: (queryArg) => ({
        url: `/questions`,
        method: 'POST',
        body: queryArg.questionData,
      }),
    }),
    getQuestion: build.query({
      query: (queryArg) => ({ url: `/questions/${queryArg.questionId}` }),
    }),
    updateQuestion: build.mutation({
      query: (queryArg) => ({
        url: `/questions/${queryArg.questionId}`,
        method: 'PUT',
        body: queryArg.questionData,
      }),
    }),
    patchQuestion: build.mutation({
      query: (queryArg) => ({
        url: `/questions/${queryArg.questionId}`,
        method: 'PATCH',
        body: queryArg.questionData,
      }),
    }),
    deleteQuestion: build.mutation({
      query: (queryArg) => ({
        url: `/questions/${queryArg.questionId}`,
        method: 'DELETE',
      }),
    }),
    getQuestionsFromTenant: build.query({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/questions` }),
    }),
    createQuestionInTenant: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/questions`,
        method: 'POST',
        body: queryArg.questionData,
      }),
    }),
    getQuestionFromTenant: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/questions/${queryArg.questionId}`,
      }),
    }),
    updateQuestionFromTenant: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/questions/${queryArg.questionId}`,
        method: 'PUT',
        body: queryArg.questionData,
      }),
    }),
    deleteQuestionFromTenant: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/questions/${queryArg.questionId}`,
        method: 'DELETE',
      }),
    }),
    getRuleOfTenant: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/rules/${queryArg.name}`,
      }),
    }),
    removeRuleFromTenant: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/rules/${queryArg.name}`,
        method: 'DELETE',
      }),
    }),
    getRulesOfTenant: build.query({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/rules` }),
    }),
    addRuleToTenant: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/rules`,
        method: 'POST',
        body: queryArg.body,
        headers: { sync: queryArg.sync },
      }),
    }),
    getTenantsByTenantIdStats: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/stats`,
        params: { fromDate: queryArg.fromDate, toDate: queryArg.toDate },
      }),
    }),
    getTenants: build.query({
      query: () => ({ url: `/tenants` }),
    }),
    postTenants: build.mutation({
      query: (queryArg) => ({
        url: `/tenants`,
        method: 'POST',
        body: queryArg.tenantData,
      }),
    }),
    getTenantsByTenantId: build.query({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}` }),
    }),
    patchTenantsByTenantId: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}`,
        method: 'PATCH',
        body: queryArg.tenantData,
      }),
    }),
    deleteTenantsByTenantId: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}`,
        method: 'DELETE',
      }),
    }),
    putTenantsByTenantIdRotate: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/rotate`,
        method: 'PUT',
      }),
    }),
    getTenantsByTenantIdInfo: build.query({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/info` }),
    }),
    getTenantsInfo: build.query({
      query: () => ({ url: `/tenants/info` }),
    }),
    getAllTransformers: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/transformers`,
      }),
    }),
    insertTransformer: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/transformers`,
        method: 'POST',
        body: queryArg.transformerData,
      }),
    }),
    getOneTransformer: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/transformers/${queryArg.transformerId}`,
      }),
    }),
    updateTransformer: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/transformers/${queryArg.transformerId}`,
        method: 'PATCH',
        body: queryArg.transformerData,
      }),
    }),
    deleteTransformer: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/transformers/${queryArg.transformerId}`,
        method: 'DELETE',
      }),
    }),
    getAllTransformersByName: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/transformers/${queryArg.name}`,
      }),
    }),
    getAllWidgets: build.query({
      query: (queryArg) => ({ url: `/tenants/${queryArg.tenantId}/widgets` }),
    }),
    insertWidget: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/widgets`,
        method: 'POST',
        body: queryArg.widgetData,
      }),
    }),
    getOneWidget: build.query({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/widgets/${queryArg.widgetId}`,
      }),
    }),
    updateWidget: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/widgets/${queryArg.widgetId}`,
        method: 'PATCH',
        body: queryArg.widgetData,
      }),
    }),
    deleteWidget: build.mutation({
      query: (queryArg) => ({
        url: `/tenants/${queryArg.tenantId}/widgets/${queryArg.widgetId}`,
        method: 'DELETE',
      }),
    }),
    exportData: build.query({
      query: (queryArg) => ({
        url: `/tools/export`,
        headers: {
          tenants: queryArg.tenants,
          collections: queryArg.collections,
          sort: queryArg.sort,
          projection: queryArg.projection,
        },
        params: { format: queryArg.format },
      }),
    }),
    importData: build.mutation({
      query: (queryArg) => ({
        url: `/tools/import`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          tenants: queryArg.tenants,
          collections: queryArg.collections,
        },
      }),
    }),
    getAllHeartbeats: build.query({
      query: () => ({ url: `/heartbeat/all` }),
    }),
    getHearbeatStatus: build.query({
      query: () => ({ url: `/heartbeat/status` }),
    }),
    setAutoScaler: build.mutation({
      query: (queryArg) => ({
        url: `/heartbeat/autoscaling`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    reloadEnvironment: build.mutation({
      query: () => ({ url: `/heartbeat/reloadenv`, method: 'POST' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as fcaApiSlice };
export const {
  useCreateAnalysisMutation,
  useGetAllAnalysesQuery,
  useUpdateAnalysisWithNewPictureMutation,
  useGetAnalysisByAnalysisIdQuery,
  useUpdateAnalysisParametersMutation,
  useGetAnalysisPictureQuery,
  useAddOtherImageToanalysisMutation,
  useGetAnalysisRecommendationQuery,
  useSendAnalysisQuizzAnswerMutation,
  useGetAnalysisFacemeshQuery,
  useGetAnalysisDifferencesQuery,
  useGetAnalysisMasksQuery,
  useGetAnalysisMaskPointsQuery,
  useGetAnalysisMaskImageQuery,
  useTransformAnalysisWithTransformerFromTenantMutation,
  useSendEmailRecommendationMutation,
  useCreateExportAnalysesTaskMutation,
  useGetAnalysisExportFileQuery,
  useCreateAnalysisTemporalLinkMutation,
  useGetAnalysisFromTemporalCodeQuery,
  useStartAnalysisOtpProcessMutation,
  useValidateAnalysisOtpQuery,
  useLinkAnalysisWithUserMutation,
  usePatchAnalysisChannelUserMutation,
  useGetAnalysisMeshQuery,
  useGetAllAppsQuery,
  useInsertAppMutation,
  useGetAppByAppIdQuery,
  usePatchAppMutation,
  useDeleteAppMutation,
  useGetGenevaPaletteMutation,
  useGetGenevaSegmentationMutation,
  useGetGenevaEnhanceMutation,
  useGetGenevaMakeupMutation,
  useGetAllGenevaMakeupModelsQuery,
  useCreateGenevaMakeupModelMutation,
  useGetOneGenevaMakeupModelQuery,
  useUpdateGenevaMakeupModelMutation,
  useRemoveGenevaMakeupModelMutation,
  useLoginUserMutation,
  useLoginAppMutation,
  useRefreshUserMutation,
  useLogoutUserMutation,
  useRefreshAppMutation,
  useGetAuthUserWhoamiQuery,
  useGetAuthAppWhoamiQuery,
  useGetAllChannelUsersQuery,
  useCreateChannelUserMutation,
  useGetChannelUserQuery,
  useUpdateChannelUserMutation,
  useDeleteChannelUserMutation,
  useDeleteChannelUserAndAllDataMutation,
  useGetAllChannelUsersByChannelQuery,
  useGetChannelUserByChannelQuery,
  useGetChannelUserContextQuery,
  useUpdateChannelUserContextMutation,
  usePatchChannelUserContextMutation,
  useDeleteChannelUserContextMutation,
  useGetChannelUserContextsQuery,
  useGetChannelUserConsolidatedContextQuery,
  useLinkChannelUsersMutation,
  useUnlinkChannelUserMutation,
  useCreateChannelUserConsentsMutation,
  useGetAllUsersQuery,
  useCreateUserMutation,
  useGetUserQuery,
  usePatchUserMutation,
  useDeleteUserMutation,
  useModifyEmailByUserIdMutation,
  useModifyEmailMutation,
  useDeleteUserAndAllDataMutation,
  useEnsureUserLinkedWithChannelUserMutation,
  useGetUserTotpQuery,
  useStoreUserTotpMutation,
  useRotateUserTotpMutation,
  useInitRecoverPasswordMutation,
  useValidateRecoverPasswordMutation,
  useStartPlannerMutation,
  useStopPlannerMutation,
  useReloadPlannerMutation,
  useRefreshPlannerMutation,
  useAbortAllPlannerMutation,
  useDeferAllPlannerMutation,
  useGetExecutorsPlannerQuery,
  useGetPlannerStatusQuery,
  useGetPlannerByTenantIdStatsQuery,
  useGetAllTaskPlannersQuery,
  useCreateTaskPlannerMutation,
  useGetOneTaskPlannerQuery,
  useUpdateTaskPlannerMutation,
  useDeleteTaskPlannerMutation,
  useStartTaskPlannerMutation,
  useStopTaskPlannerMutation,
  useRefreshTaskPlannerMutation,
  useDeferAllTaskPlannerMutation,
  useAbortAllTaskPlannerMutation,
  useGetTenantPlannerStatusQuery,
  useGetTaskPlannerStatusQuery,
  useGetAllTasksQuery,
  useCreateTaskMutation,
  useGetOneTaskQuery,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
  useGetDatabasesQuery,
  usePostDatabasesMutation,
  useGetDatabasesByDatabaseIdQuery,
  usePutDatabasesByDatabaseIdMutation,
  usePatchDatabasesByDatabaseIdMutation,
  useDeleteDatabasesByDatabaseIdMutation,
  useGetStoragesQuery,
  usePostStoragesMutation,
  useGetStoragesByStorageIdQuery,
  usePutStoragesByStorageIdMutation,
  usePatchStoragesByStorageIdMutation,
  useDeleteStoragesByStorageIdMutation,
  useGetDatabaseByCollectionNameQuery,
  usePostDatabaseByCollectionNameMutation,
  useGetDatabaseByCollectionNameFindOneQuery,
  useGetDatabaseByCollectionNameAndItemIdQuery,
  usePutDatabaseByCollectionNameAndItemIdMutation,
  usePatchDatabaseByCollectionNameAndItemIdMutation,
  useDeleteDatabaseByCollectionNameAndItemIdMutation,
  useGetStorageByContainerNameAndFileIdQuery,
  useDeleteStorageByContainerNameAndFileIdMutation,
  useGetStorageImageByContainerNameAndFileIdQuery,
  usePostStorageByContainerNameMutation,
  usePostStorageImageByContainerNameMutation,
  useInsertFileInVolumeMutation,
  useDeleteFileFromVolumeMutation,
  useGetTenantGroupsQuery,
  useAddTenantGroupMutation,
  useGetTenantGroupQuery,
  useUpdateTenantGroupMutation,
  useDeleteTenantGroupMutation,
  useSyncTenantsWithTenantMutation,
  useSetFormulaForTenantGroupMutation,
  useRemoveFormulaFromTenantGroupMutation,
  useSetFormulaInfoForTenantGroupMutation,
  useRemoveFormulaInfoFromTenantGroupMutation,
  useSetFormulaInfoLocaleForTenantGroupMutation,
  useRemoveFormulaInfoLocaleFromTenantGroupMutation,
  useUploadRulesExcelToTenantGroupMutation,
  useDeleteRuleFromTenantGroupMutation,
  useAddTransformerToTenantGroupMutation,
  useUpdateTransformerOfTenantGroupMutation,
  useDeleteTransformerFromTenantGroupMutation,
  useGetAllStatsFromTenantGroupQuery,
  useGetAllChatbotsQuery,
  useInsertChatbotMutation,
  useGetOneChatbotQuery,
  useUpdateChatbotMutation,
  useDeleteChatbotMutation,
  useAskChatbotMutation,
  useGetAllEventsQuery,
  useGetOneEventQuery,
  useGetFormulasQuery,
  useGetFormulaQuery,
  useUpdateFormulaMutation,
  useDeleteFormulaMutation,
  useAddFormulasTestsMutation,
  useGetFormulasTestsQuery,
  useGetFormulasInfoQuery,
  useGetFormulaInfoQuery,
  useUpdateFormulaInfoMutation,
  useDeleteFormulaInfoMutation,
  useGetFormulasInfoLocalesQuery,
  useGetFormulaInfoLocaleQuery,
  useUpdateFormulaInfoLocaleMutation,
  useDeleteFormulaInfoLocaleMutation,
  useGetAllProductsQuery,
  useCreateProductMutation,
  useGetAllProductsByLocaleQuery,
  useGetProductQuery,
  usePatchProductMutation,
  useDeleteProductMutation,
  useGetProductByLocaleQuery,
  useGetAllProductsFromTenantQuery,
  useCreateProductInTenantMutation,
  useGetAllProductsByLocaleFromTenantQuery,
  useGetProductFromTenantQuery,
  usePatchProductFromTenantMutation,
  useDeleteProductFromTenantMutation,
  useGetProductByLocaleFromTenantQuery,
  useProductsBatchEndMutation,
  useCreateProductsBatchMutation,
  useInsertProductsBatchExcelMutation,
  useExportProductsExcelQuery,
  useGetAllQuestionsQuery,
  useCreateQuestionMutation,
  useGetQuestionQuery,
  useUpdateQuestionMutation,
  usePatchQuestionMutation,
  useDeleteQuestionMutation,
  useGetQuestionsFromTenantQuery,
  useCreateQuestionInTenantMutation,
  useGetQuestionFromTenantQuery,
  useUpdateQuestionFromTenantMutation,
  useDeleteQuestionFromTenantMutation,
  useGetRuleOfTenantQuery,
  useRemoveRuleFromTenantMutation,
  useGetRulesOfTenantQuery,
  useAddRuleToTenantMutation,
  useGetTenantsByTenantIdStatsQuery,
  useGetTenantsQuery,
  usePostTenantsMutation,
  useGetTenantsByTenantIdQuery,
  usePatchTenantsByTenantIdMutation,
  useDeleteTenantsByTenantIdMutation,
  usePutTenantsByTenantIdRotateMutation,
  useGetTenantsByTenantIdInfoQuery,
  useGetTenantsInfoQuery,
  useGetAllTransformersQuery,
  useInsertTransformerMutation,
  useGetOneTransformerQuery,
  useUpdateTransformerMutation,
  useDeleteTransformerMutation,
  useGetAllTransformersByNameQuery,
  useGetAllWidgetsQuery,
  useInsertWidgetMutation,
  useGetOneWidgetQuery,
  useUpdateWidgetMutation,
  useDeleteWidgetMutation,
  useExportDataQuery,
  useImportDataMutation,
  useGetAllHeartbeatsQuery,
  useGetHearbeatStatusQuery,
  useSetAutoScalerMutation,
  useReloadEnvironmentMutation,
} = injectedRtkApi;
