import { createSlice } from '@reduxjs/toolkit';
import { defaultsDeep } from 'lodash';

const defaultState = {
  auth: {
    tokenInfo: null,
    userInfo: null,
    refreshToken: null,
    expires: null,
  },
  ui: {
    sidebarUnfoldable: true,
    sidebarShow: true,
    toasts: [],
  },
  core: {
    tenants: null,
    selectedTenant: null,
    tenantGroups: [],
  },
};

let persistedState = localStorage.getItem('app');

if (persistedState) {
  persistedState = JSON.parse(persistedState);
} else {
  persistedState = {};
}

const initialState = defaultsDeep(persistedState, defaultState);

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    // Auth
    setToken: (state, action) => {
      const tokenInfo = action.payload;
      state.auth.tokenInfo = tokenInfo;
      state.auth.refreshToken = tokenInfo.refreshToken;
      state.auth.expires = Date.now() + 900 * 1000;

      const [_, data] = tokenInfo.accessToken.split('.');
      const userInfo = JSON.parse(atob(data));
      state.auth.userInfo = {
        ...userInfo,
        isGlobalAdmin: userInfo.tenants[0] === '*',
        isBrandAdmin: userInfo.role === 'admin',
        isBeautyConsultant: userInfo.role === 'beautyConsultant',
      };
    },
    clearToken: (state) => {
      state.auth = defaultState.auth;
      state.core = defaultState.core;
    },

    // UI
    setSidebarUnfoldable: (state, action) => {
      state.ui.sidebarUnfoldable = action.payload;
    },
    setSidebarShow: (state, action) => {
      state.ui.sidebarShow = action.payload;
    },

    // Core
    setTenants: (state, action) => {
      state.core.tenants = action.payload;
    },
    setSelectedTenant: (state, action) => {
      state.core.selectedTenant = action.payload;
    },
    setTenantGroups: (state, action) => {
      state.core.tenantGroups = action.payload;
    },

    // Toasts
    pushToast: (state, action) => {
      state.ui.toasts.push(action.payload); // { body: '', color: '' }
    },
    clearToasts: (state, action) => {
      state.ui.toasts = [];
    },
  },
});

export const {
  // Auth
  setToken,
  clearToken,

  // UI
  setSidebarUnfoldable,
  setSidebarShow,
  pushToast,
  clearToasts,

  // Core
  setTenants,
  setSelectedTenant,
  setTenantGroups,
} = appSlice.actions;

// Auth
export const getRefreshToken = (state) => state.app.auth.refreshToken;
export const getToken = (state) => state.app.auth.tokenInfo?.accessToken;
export const getUser = (state) => state.app.auth.userInfo;
export const hasExpired = (state) => {
  if (!state.app.auth.userInfo) return true;
  return Date.now() > state.app.auth.userInfo.exp * 1000;
};

// UI
export const getSidebarShow = (state) => state.app.ui.sidebarShow;
export const getSidebarUnfoldable = (state) => state.app.ui.sidebarUnfoldable;
export const getToasts = (state) => state.app.ui.toasts;

// Core
export const getTenants = (state) => state.app.core.tenants;
export const getSelectedTenant = (state) => state.app.core.selectedTenant;
export const getTenantGroups = (state) => state.app.core.tenantGroups;

export default appSlice.reducer;
